::-webkit-scrollbar {
width: 7px;
height: 7px;
}

::-webkit-scrollbar-track {
background: #fff;
margin-right: 0;
margin-top: 0;
}

::-webkit-scrollbar-thumb {
background: #eee;
border-radius: 100px;
}
