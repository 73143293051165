.section {

.dynamic {

    font-size: 20px;
    margin: 0 auto;
    font-weight: 400;
    background-color: white;
    padding: 40px 0;
    position: relative;

    h2 {

        font-size: 64px;
        margin-bottom: 15px;
        text-align: left;

    }

    h3 {

        font-size: 32px;
        margin-bottom: 15px;

    }

    // @include atXSmall {

    //     width: 100%;
    //     font-size: 14px;

    // }

    b,
    strong {

        font-weight: bold;

    }

    p {

        margin-bottom: 20px;

        &.main-paragraph {

            font-size: 24px;
            // line-height: 100%;

            // @include atXSmall {

            //     font-size: 18px;

            // }

        }

    }

    ul {

        list-style-type: disc;
        padding: 15px 25px;

        li {

            margin-bottom: 10px;

            &:last-child {

                margin-bottom: 0;

            }

        }

    }

}

}