
noscript {

    color: #666;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    z-index: 99999;
    padding: 20px;
    background: white;

    span {

        background-color: #666;
        mask-image: url(/assets/img/logo.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
        width: 240px;
        height: 50px;
        display: block;
        margin: 0 auto 20px;

    }

}