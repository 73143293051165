#loading-splash {

    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: opacity .5s, visibility .5s;

    // .dots-loading {
    
    //     div,
    //     &:before,
    //     &:after {
    
    //         margin: 12vw 1vw;
    //         display: inline-block;
    //         width: 6vw;
    //         height: 6vw;
    //         border-radius: 50%;
    //         background-color: $mainColor;
    //         opacity: 0;
    
    //     }

    // }

    html.loaded & {

        opacity: 0;
        visibility: hidden;

    }

}