.table-container {

    overflow-x: auto;

    table {

        min-width: 480px;

        @include atXSmall {

            min-width: 640px;

        }
        
    }

    small {

        padding: 5px 10px;
        
        min-width: 480px;

        @include atXSmall {

            min-width: 640px;

        }

    }

}