.miniAlertBg {

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: 9998;

}

.miniAlert {

    text-align: center;
    font-size: 16px;
    // font-weight: bold;
    display: block;
    color: #fff;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -250%) scale(0.1) rotate(-45deg);
    z-index: 9999;
    box-shadow: 10px 10px 0 rgba(0,0,0, 0.1);
    border-radius: 10px;
    transition: all 0.9s cubic-bezier(0.87, -0.41, 0.19, 1.44);
    padding: 10px 20px;
    opacity: 0;
    visibility: hidden;
    background-color: $mainColor;
    color: white;

    &.show {

        opacity: 1;
        visibility: visible;
        transition: 0.5s;
        transform: translate(-50%, -50%) scale(1);

        &.show--1 { transform: translate(-50%, -100%) scale(1) rotate(4deg); }
        &.show--2 { transform: translate(-50%, -90%) scale(1) rotate(-4deg); }
        &.show--3 { transform: translate(-50%, -80%) scale(1) rotate(2deg); }
        &.show--4 { transform: translate(-50%, -70%) scale(1) rotate(-2deg); }
        &.show--5 { transform: translate(-50%, -60%) scale(1) rotate(-6deg); }
        &.show--6 { transform: translate(-50%, -50%) scale(1) rotate(6deg); }
        &.show--7 { transform: translate(-50%, -40%) scale(1) rotate(8deg); }
        &.show--8 { transform: translate(-50%, -30%) scale(1) rotate(-8deg); }

    }

    &--medium {

        max-width: 50%;

        &.show {

            transform: translate(-50%, -50%) scale(1.5);

            &.show--1 { transform: translate(-50%, -50%) scale(1.5) rotate(4deg); }
            &.show--2 { transform: translate(-50%, -50%) scale(1.5) rotate(3deg); }
            &.show--3 { transform: translate(-50%, -50%) scale(1.5) rotate(2deg); }
            &.show--4 { transform: translate(-50%, -50%) scale(1.5) rotate(1deg); }
            &.show--5 { transform: translate(-50%, -50%) scale(1.5) rotate(1.5deg); }
            &.show--6 { transform: translate(-50%, -50%) scale(1.5) rotate(2.5deg); }
            &.show--7 { transform: translate(-50%, -50%) scale(1.5) rotate(3.5deg); }
            &.show--8 { transform: translate(-50%, -50%) scale(1.5) rotate(4.5deg); }

        }

    }

}
