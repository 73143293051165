.no-data,
.no-results {

    text-align: center;
    font-size: 24px;
    padding: 40px 0;

    &:before {

        mask-image: url(/assets/img/ico/error.svg);
        mask-size: contain;
        mask-position: center center;
        mask-repeat: no-repeat;
        background-color: #333;
        content: '';
        width: 80px;
        height: 80px;
        display: block;
        margin: 0 auto 10px;
        font-weight: bold;

    }

}

.no-data {

    &:before {

        mask-image: url(/assets/img/ico/face-sad.svg);

    }

}
