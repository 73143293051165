.section {

    &--page {

        h1 {

            font-size: 64px;
            margin-bottom: 20px;
            text-align: center;

            @include atSmall {

                font-size: 48px;

            }

        }

        p {

            margin-bottom: 20px;

        }

    }

}