.table-scroll {

	overflow: hidden;
    overflow-x: auto;
    margin-bottom: 15px;

	table {

		border-collapse: collapse;
	    width: 100%;
        margin: 0 -10px 0;
	    // margin-bottom: 20px;

		td,
		th {

			padding: 10px;

		}

		th {

			font-weight: bold;

		}

	}

}
