.section {

    &--title {

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 20px;
        text-align: center;
        min-height: 0;

        h2 {

            font-size: 64px;
            color: white;
            position: relative;
            z-index: 10;

            @include atXSmall {

                font-size: 48px;
    
            }

            @include atXXSmall {

                font-size: 32px;
    
            }

        }

        .bg {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #333;
            background-size: cover;
            background-position: center center;

            img {

                object-fit: cover;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;

            }

            &:after {

                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.5));
                z-index: 2;

            }

        }

    }

}