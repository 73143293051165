.popup-fs {

    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;

    @include atSmall {

        display: block;
        overflow: auto;

    }

    a,
    button {

        text-decoration: none;
        color: $mainColor;
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
        display: inline;

        img {

            display: none;

        }

    }

    .btn {

        background: $mainColor;
        border: 2px solid $mainColor;
        color: $mainColor;
        border-radius: 10px;
        padding: 16px 20px;
        color: white;
        // font-weight: bold;
        width: 100%;
        font-size: 24px;
        cursor: pointer;

        &--close {

            position: absolute;
            top: 40px;
            right: 40px;
            width: 40px;
            height: 40px;
            border-radius: 1000px;
            text-indent: -9999px;
            overflow: hidden;
            padding: 0;
            z-index: 100;
            mask-image: url(/assets/img/ico/error.svg);
            mask-size: contain;
            mask-position: center center;
            mask-repeat: no-repeat;

            @include atSmall {

                background-color: white;
                border: 2px solid white;
                top: 20px;
                right: 20px;
    
            }

        }

    }

    .col {

        flex: 1;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include atSmall {

            display: block;

        }

        &--bg {

            color: white;

            a,
            button {

                color: rgba(255,255,255,0.6);
                
            }

        }

        .box {

            position: relative;
            z-index: 10;
            width: 100%;
            max-width: 480px;
            padding: 80px 20px;
            margin: 0 auto;

            p {

                margin-bottom: 10px;

            }

            form {

                width: 100%;
                max-width: 320px;
                margin: 20px auto;

                select,
                textarea,
                input {

                    display: block;
                    width: 100%;
                    background-color: #eee;
                    border: 0;
                    border-radius: 10px;
                    padding: 20px;
                    margin: 10px 0;
                    appearance: none;

                }

                textarea {

                    min-height: 100px;
                    resize: vertical;

                }

                .input-container {

                    display: block;

                }
                
            }

            h3 {

                font-size: 32px;
                margin-bottom: 20px;
                // font-weight: bold;

            }

            .logo {

                margin-bottom: 120px;
                transform: scale(1.5);

                @include atSmall {

                    margin-bottom: 20px;
                    transform: scale(1);

                }

                &:after {

                    content: '';
                    background-color: white;
                    mask-image: url(/assets/img/plate.svg);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center center;
                    width: 36px;
                    height: 36px;
                    display: inline-block;
                    margin-left: 10px;
                    margin-top: 0;
                    margin-bottom: -5px;
        
                }
                
                &:before {
        
                    content: 'Costopiatt';
                    font-size: 48px;
                    // font-weight: 900;
                    // font-weight: bold;
                    display: inline-block;
        
                }

            }

        }

        .bg {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #333;
            // background-color: $mainColor;
            // background-image: linear-gradient(-90deg, darken($mainColor, 0%), darken($mainColor, 10%));
            background-size: cover;
            background-position: center center;

            .isro {

                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 40px;
                background: transparent;
                z-index: 100;

            }

            img {

                object-fit: cover;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;

            }

            &:after {

                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.3));
                z-index: 2;

            }

        }

    }

    &--nav {

        // background-color: $mainColor;
        background-color: #333;
        overflow: hidden;

        canvas {

            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

        }

        .box {

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            a,
            button {

                color: white;
                font-size: 24px;
                text-align: center;
                display: block;
                padding: 20px;
                background-color: transparent;
                border: 0;

            }

        }

        .btn {

            &--close {

                background-color: white;
                border-color: white;

            }

        }

    }

}