.btn {

    cursor: pointer;

    .loading {

        display: inline-block;
        width: 0;
        height: 24px;
        margin-right: 0;
        vertical-align: sub;
        position: relative;
        overflow: hidden;
        transition: .3s;
        opacity: 0;
        visibility: hidden;

        &:after {

            content: '';
            border-radius: 50%;
            width: 20px;
            height: 20px;
            border: 2px solid rgba(255, 255, 255, 0.1);
            border-top-color: #fff;
            animation: spinCenter 0.7s infinite linear;
            z-index: 5;
            transition: .5s;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

        }

    }

    &:disabled {

        .loading {

            width: 24px;
            margin-right: 10px;
            opacity: 1;
            visibility: visible;

        }

    }

    &--main {

        .loading {

            height: 32px;
        
        }

        &:disabled .loading {

            margin-right: 20px;

        }

    }

}