@keyframes loadingBar {
    0% { background-position: 0% 50%;}
    50% { background-position: 100% 50%;}
    100% { background-position: 0% 50%;}
}

.popup-admin {

    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    @include atXSmall {

        align-items: flex-start;

    }

    .box {

        width: calc(100% - 200px);
        max-height: calc(100vh - 40px);
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 20px 20px 0 rgba(0,0,0,0.1);
        position: relative;
        padding-top: 10px;
        max-width: 1280px;

        @include atSmall {

            width: calc(100% - 40px);

        }

        &__scroll {

            display: flex;
            padding: 20px;
            min-height: 120px;
            max-height: calc(100vh - 220px);
            overflow-y: auto;

            @include atSmall {

                display: block;
    
            }

        }

        &__footer {

            text-align: right;
            padding: 20px;

            @include atSmall {

                text-align: center;
                padding-left: 0;
                padding-right: 0;
    
            }

            .btn {

                background: $mainColor;
                border-radius: 10px;
                padding: 16px 32px;
                color: white;
                cursor: pointer;
                display: inline-block;
                margin: 0 5px;
                border: 0;

                &--close {

                    background-color: #333;

                }

                &--delete {

                    padding: 0 10px;
                    background: transparent;
                    color: #333;

                }

            }
            
        }

        &__header {

            font-weight: bold;

            .btn {

                background: #333;
                color: $mainColor;
                border-radius: 10px;
                color: white;
                cursor: pointer;
                border: 0;
        
                &--close {
        
                    position: absolute;
                    top: 28px;
                    right: 10px;
                    width: 30px;
                    height: 30px;
                    border-radius: 1000px;
                    text-indent: -9999px;
                    overflow: hidden;
                    padding: 0;
                    z-index: 100;
                    mask-image: url(/assets/img/ico/error.svg);
                    mask-size: contain;
                    mask-position: center center;
                    mask-repeat: no-repeat;
        
                    @include atXSmall {
        
                        display: none;
            
                    }
        
                }
        
            }

            @include atXSmall {

                overflow: auto;
                border-radius: 10px 10px 0 0;
                
            }

            &:after {

                position: absolute;
                background: #ddd;
                background-image: linear-gradient(to right, #ddd, #ccc);
                content: '';
                width: 100%;
                height: 70px;
                top: 20px;
                z-index: -1;
                border-radius: 10px;

            }

            ul {

                display: flex;

                li {

                    padding: 20px;
                    background-color: #eee;
                    // flex: 1;
                    text-align: center;
                    cursor: pointer;
                    // font-weight: bold;

                    @include atXSmall {

                        flex: 1;
                        white-space:nowrap;
                        
                    }

                    &:last-child {

                        border-radius: 0 10px 0 0;

                    }

                    &:first-child {

                        border-radius: 10px 0 0 0;

                    }

                    &.active {

                        background-color: white;
                        box-shadow: 0 0 20px rgba(0,0,0,0.2);
                        position: relative;
                        z-index: 10;
                        border-radius: 10px 10px 0 0;
                        padding-top: 25px;
                        margin-top: -10px;

                        @include atXSmall {

                            margin-top: 0;
                            border-radius: 0;
                            padding-top: 20px;
                            
                        }

                    }

                }

            }

        }

        &__area {

            background-color: white;
            z-index: 10;
            position: relative;
            
            .loading-bar {

                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 10px;
                background: $mainColor;
                animation: loadingBar 2s ease infinite;
        
                background-image: linear-gradient(-45deg,
                    white,
                    $mainColor,
                    white
                );

                background-size: 400% 400%;

            }

        }

    }

}

