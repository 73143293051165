@keyframes navBar1 {
    0% { width: 100%; left: 0; right: auto; }
    33% { width: 0%; left: 0; right: auto; }
    66% { width: 0%; left: auto; right: 0; }
    100% { width: 100%; left: auto; right: 0; }
}

@keyframes navBar2 {
    0% { width: 50%; left: 50%; right: auto; }
    25% { width: 0%; left: 50%; right: auto; }
    50% { width: 0%; left: auto; right: 0; }
    100% { width: 50%; left: auto; right: 0; }
}

.header {

    height: 90px;
    box-shadow: -5px 5px 20px rgba(8, 42, 62, 0.05);

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: white;
    min-width: 300px;

    .navigation-button {

        width: 30px;
        height: 22px;
        cursor: pointer;
        margin-left: auto;
        order: 2;
        margin-right: 20px;
        border: 0;
        display: none;
        position: relative;
        background-color: transparent;

        @include atXSmall {
            display: block;
        }

        &:after,
        &:before {

            width: 100%;
            height: 2px;
            background-color: #333;
            border-radius: 100px;
            content: "";
            position: absolute;
            transition: .3s;

        }

        &:before {

            top: 5px;
            left: 0;

        }

        &:after {

            left: 50%;
            bottom: 5px;
            width: 50%;

        }

        &:hover {

            &:before {

                animation-duration:1s;
                animation-iteration-count:1;
                animation-name:navBar1;

            }

            &:after {

                animation-duration:1s;
                animation-iteration-count:1;
                animation-name:navBar2;

            }

        }

    }

    .logo {

        margin-left: 20px;
        position: relative;

        @include atXXSmall {
            transform: scale(.7);
            transform-origin: left center;
            position: absolute;
            left: 0;
        }

        a {

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

        }

        &:after {

            content: '';
            background-color: #333;
            mask-image: url(/assets/img/plate.svg);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center center;
            width: 36px;
            height: 36px;
            display: inline-block;
            margin-left: 10px;
            margin-top: 0;
            margin-bottom: -5px;

        }
        
        &:before {

            content: 'Costopiatt';
            font-size: 48px;
            // font-weight: 900;
            // font-weight: bold;
            display: inline-block;

        }

    }

    ul {

        margin-left: auto;
        order: 2;
        margin-right: 20px;

        @include atXSmall {
            display: none;
        }

        li {

            display: inline-block;
            margin-left: 25px;

            @include atSmall {
                margin-left: 15px;
            }

            // &.rsp-m {

            //     @include atXSmall {
            //         display: inline-block;
            //     }

            // }

            &.active {

                a,
                button {

                    color: $mainColor;

                }

            }

            button,
            a {

                text-decoration: none;
                color: #333;
                display: inline-block;
                // font-weight: bold;
                background: transparent;
                cursor: pointer;
                transition: .5s;

                &:hover {

                    color: $mainColor;

                }

                &.avatar {

                    overflow: hidden;
                    background-color: #eee;
                    border-radius: 10px;
                    height: 48px;
                    width: 48px;
                    margin: -5px -10px 0;
                    vertical-align: middle;
                    position: relative;

                    &:after {

                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 50%;
                        height: 50%;
                        transform: translate(-50%,-50%);
                        mask-image: url(/assets/img/ico/face-happy.svg);
                        // mask-image: url(/assets/img/ico/settings.svg);
                        mask-size: contain;
                        mask-position: center center;
                        mask-repeat: no-repeat;
                        // background-color: #333;
                        // background-color: #ff6600;
                        background-color: #999;
                        content: '';

                    }

                    @include atSmall {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    img {

                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        background-color: white;
                        position: relative;
                        z-index: 10;

                    }

                    span {

                        display: none;

                    }

                }
                
                &.btn {

                    border: 1px solid $mainColor;
                    color: $mainColor;
                    border-radius: 10px;
                    padding: 12px 20px;

                    &:hover {

                        color: darken($mainColor, 10%);
                        border-color: darken($mainColor, 10%);

                    }

                    &.register {

                        background-color: $mainColor;
                        color: white;
                        margin-left: -15px;

                        @include atSmall {
                            margin-left: -5px;
                        }

                        &:hover {

                            background-color: darken($mainColor, 10%);
                            color: white;
    
                        }

                    }

                }

            }

        }

    }

}