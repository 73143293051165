.section {

    &--try {

        // background-color: #fff;
        // background-color: #eee;
        display: flex;
        overflow: hidden;
        color: white;

        .btn {

            // font-weight: bold;
            // text-transform: uppercase;
            display: inline-block;
            // margin: 30px auto 0;
            border-radius: 10px;
            color: #333;
            background-color: white;
            font-size: 24px;
            font-size: clamp(24px, 1.6vw, 1.6vw);
            padding: 1.7vw 2.8vw;
            border: 0;

            padding-top: clamp(24px, 1.7vw, 1.7vw);
            padding-right: clamp(40px, 2.8vw, 2.8vw);
            padding-left: clamp(40px, 2.8vw, 2.8vw);
            padding-bottom: clamp(24px, 1.7vw, 1.7vw);

            // font-weight: normal;
            cursor: pointer;
            transition: .5s;

            &:hover {

                background-color: darken($mainColor, 0%);
                color: white;

            }

        }

        .bg:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7));
            z-index: 2;
        }

        .section {

            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 10;

            @include atMedium {

                display: block;
                text-align: center;

            }

            div {

                flex: 1;
    
                &:first-child {
    
                    padding-right: 40px;

                    @include atMedium {

                        padding-right: 0;

                    }
            
                }
    
                &:last-child {
    
                    padding: 20px 0;
            
                }
    
            }

        }

        h4 {

            font-size: 48px;
            margin: 0;

            @include atSmall {

                margin: 20px 0;
                font-size: 32px;

            }
        
        }

        p {

            margin: 40px 0;
            font-size: 24px;

            @include atSmall {

                margin: 20px 0;
                font-size: 18px;

            }

        }

        .btn {

            margin-right: 10px;

        }

        .image-container {

            perspective-origin: left center;
            perspective: 1500px;
            transform-style: preserve-3d;

            img {

                // height: 500px;
                // margin: -100px;
                // margin-left: 20px;
                transform: rotateY(-25deg) rotateX(25deg);
                backface-visibility: hidden;
                height: 400px;
                width: 480px;
                margin: -50px;
                margin-left: -30px;
                border-radius: 5px;
                transition: .3s;
                box-shadow: 5px 5px 50px rgba(0,0,0,0.1);
                position: relative;
                z-index: 10;

                &:hover {

                    opacity: 0.1;

                }

                &:last-of-type {

                    position: absolute;
                    // margin-left: -150px;
                    left: 40px;
                    top: 0;
                    z-index: 5;

                }

                @include atMedium {

                    margin: 20px 40px -20px;
                    height: auto;
                    transform: none;
                    max-width: calc(100% - 80px);

                    &:last-of-type {

                        position: absolute;
                        left: 50%;
                        margin-left: 20px;
                        transform: translateX(-50%);
    
                    }

                }

                @include atSmall {

                    border-radius: 5px;

                }

            }

        }

    }

}