.footer {

    text-align: center;
    padding: 80px 40px;
    position: relative;
    z-index: 10;
    background-color: rgba(255,255,255,0.9);

    ul {

        margin-bottom: 20px;

        li {

            display: inline-block;
            margin: 0 10px;

        }

    }

    a {

        text-decoration: none;

    }

    select {

        // appearance: none;
        // background-color: #eee;
        border: 0;
        border-radius: 10px;
        padding: 10px;
        background-color: transparent;

    }

}