.section {

    &--faq {

        background-color: white;

        .section {

            column-count: 2;
            column-gap: 40px;
            // font-size: 24px;

            @include atXSmall {

                column-count: 1;
                column-gap: 0;

            }

            h3 {

                font-weight: bold;
                font-size: 28px;
                margin-bottom: 20px;

            }

            li {

                padding: 40px 0;
                break-inside: avoid;
                // border-bottom: 1px solid #eee;

                @include atXSmall {

                    padding: 20px 0;
    
                }

                li {

                    padding: 0;
                    list-style-type: square;
                    margin: 20px;
                    border: 0;

                }

            }

        }

    }

}