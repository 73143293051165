#cookies {

    &:before {

        mask-image: url(/assets/img/ico/cookie.svg);
        mask-size: contain;
        mask-position: center center;
        mask-repeat: no-repeat;
        width: 40px;
        height: 40px;
        // background-color: $mainColor;
        background-color: #333;
        content: '';
        margin: 0 auto 10px;
        display: block;

    }

    text-align: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    // right: 20px;
    // transform: translate(0, 40px);
    min-width: 240px;
    max-width: 320px;
    width: calc(100% - 40px);
    z-index: 9000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    padding: 20px;
    background-color: white;
    border-radius: 10px 10px 0 0;

    a {

        text-decoration: none;

    }

    h4 {

        font-size: 24px;
        margin-bottom: 10px;

    }

    .btn {

        margin-top: 10px;
        border-radius: 10px;
        padding: 12px 20px;
        background-color: $mainColor;
        color: white;
        display: inline-block;
        transition: .3s;

        &:hover {

            background-color: darken($mainColor, 10%);

        }

    }

    // transition: 0.5s;
    // opacity: 0;
    // visibility: hidden;
    // transform: translate(-50%, 40px);

    // opacity: 1;
    // visibility: visible;
    transform: translate(-50%, 0);
    // transform: translate(0, 0);

}