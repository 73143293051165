.section {

    position: relative;
    // min-height: 480px;
    padding: 40px 20px;
    z-index: 10;

    h2 {

        font-size: 48px;
        text-align: center;
        // font-weight: bold;

    }

    &--min-width {

        max-width: 640px;
        margin: 0 auto;

    }

    &--max-width {

        max-width: 1280px;
        margin: 0 auto;

    }

}