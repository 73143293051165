@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes spinCenter {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
}

@keyframes spinCenterInvert {
    0% { transform: translate(-50%,-50%) rotate(180deg); }
    100% { transform: translate(-50%,-50%) rotate(-180deg); }
}