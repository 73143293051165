@mixin atLarge {
	@media (max-width: 1420px) {
		@content;
	}
}

@mixin atMedium {
	@media (max-width: 1100px) {
		@content;
	}
}

@mixin atSmall {
	@media (max-width: 1000px) {
		@content;
	}
}

@mixin atXSmall {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin atXXSmall {
	@media (max-width: 480px) {
		@content;
	}
}

@mixin clearfix {
	&:after {
		content: "";
		clear: both;
		display: table;
	}
}
