.form,
form {

    // margin-top: -10px;

    &.v1 {

        display: flex;

        .avatar {

            width: 320px;
            height: 320px;
            background: #eee;
            border-radius: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin: 0 auto 20px;

            @include atSmall {
            // @include atXSmall {

                width: 240px;
                height: 240px;
    
            }

            &:after {

                content: '';
                background-color: #ccc;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-image: url(/assets/img/ico/face-happy.svg);
                width: 50%;
                height: 50%;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);

            }

            img {

                object-fit: cover;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;

            }

            & > div {

                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 20;

            }

        }

        .popup-admin & {

            display: block;

        }

        .container-side {

            padding-top: 10px;
            padding-right: 20px;

            .container-image {

                position: sticky;
                top: 110px;

                @include atSmall {
                // @include atXSmall {

                    position: relative;
                    top: 0;

                }

                .popup-admin & {

                    position: sticky;
                    top: 0;

                }

            }

            @include atSmall {
            // @include atXSmall {

                padding-right: 0;

            }

            .popup-admin & {

                position: sticky;
                top: 0;

                @include atSmall {

                    position: relative;
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    margin: 0 auto;
        
                }

            }

            .btn {

                background-color: #333;
                min-width: 200px;
                position: relative;
                // padding-top: 10px;
                // padding-bottom: 10px;

                &--add {

                    & > div {

                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 20;

                    }

                }

            }

            .image {

                width: 200px;
                height: 200px;
                background: #eee;
                border-radius: 10px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                margin-bottom: 10px;
    
                &:after {
    
                    content: '';
                    background-color: #ccc;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-image: url(/assets/img/ico/code-image2.svg);
                    width: 100px;
                    height: 100px;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
    
                }

                img {

                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 1;
    
                }
    
                @include atSmall {
                // @include atXSmall {
    
                    margin: 0 auto 10px;
        
                }

                .popup-admin & {

                    margin: 0 auto 10px;

                }

                & > div {

                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 20;

                }
    
            }

        }

        .container-form {

            flex: 1;
            
        }

        @include atSmall {
        // @include atXSmall {

            display: block;

        }

        .btn {

            // &--main {

                border: 0;
                color: white;
                background-color: $mainColor;
                border-radius: 10px;
                display: block;
                padding: 15px 20px;
                text-decoration: none;
                margin: 0 auto;

                &--main {

                    font-size: 32px;
                    padding-left: 40px;
                    padding-right: 40px;

                }

                &--duplicate,
                &--delete {

                    background-color: transparent;
                    color: #333;
                    padding-left: 0;

                }

                &--add {

                    background-color: #333;

                    &:before {

                        display: inline-block;
                        margin-right: 10px;
                        background-color: white;
                        width: 20px;
                        height: 20px;
                        content: '';
                        vertical-align: sub;
                        mask-image: url(/assets/img/ico/plus.svg);
                        mask-size: contain;
                        mask-position: center center;
                        mask-repeat: no-repeat;
        
                    }

                }

            // }

        }

        small {

            display: block;
            text-align: right;
            // font-weight: bold;

            @include atXSmall {

                text-align: center;
                
            }

        }

        .input-container {

            display: flex;
            margin: 0 -10px;

            // @include atSmall {
            @include atXSmall {

                display: block;
                
            }

            .spotlight {

                position: absolute;
                top: 45px;
                width: 100%;
                min-height: 60px;
                max-height: 150px;
                background-color: white;
                margin-left: -5px;
                z-index: 20;
                border-radius: 0 0 10px 10px;
                border: 5px solid #eee;
                overflow: scroll;
                overflow-x: hidden;
                // transition: .3s;

                ul {

                    li {

                        display: flex;
                        padding: 5px 10px;
                        border-bottom: 1px solid #eee;
                        cursor: pointer;
                        transition: .3s;
                        align-items: center;

                        &:hover {

                            background-color: #f5f5f5;

                        }

                        .image {

                            width: 40px;
                            height: 40px;
                            background-color: #eee;
                            border-radius: 10px;
                            overflow: hidden;
                            position: relative;
                            margin-right: 10px;
                            margin-left: 5px;

                            &:after {
    
                                content: '';
                                background-color: #ccc;
                                mask-repeat: no-repeat;
                                mask-position: center;
                                mask-image: url(/assets/img/ico/code-image2.svg);
                                width: 20px;
                                height: 20px;
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                
                            }

                            img {

                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                                background-color: white;
                                position: relative;
                                z-index: 10;
                                
                            }

                        }

                        .text {

                            flex: 1;

                        }

                    }

                }

            }

            &--list,
            input,
            select,
            textarea {

                display: block;
                background-color: #eee;
                padding: 15px;
                border-radius: 10px;
                position: relative;
                overflow: hidden;
                margin: 10px;
                border: 0;
                width: calc(100% - 20px);
                flex: 1;
                // border-bottom: 3px solid #ccc;

                &::-webkit-input-placeholder { color: #999; }
                ::-moz-placeholder { color: #999; }
                :-ms-input-placeholder { color: #999; }
                :-moz-placeholder { color: #999; }

            }

            &--list {

                margin: 10px 0;
                width: 100%;
                padding: 5px;
                overflow: visible;

                .search {

                    // background-color: white;
                    flex: 1;
                    border-radius: 10px;
                    position: relative;
                    overflow: hidden;
        
                    .reset {
        
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 65px;
                        border: 0;
                        height: auto;
                        z-index: 20;
                        cursor: pointer;
                        background-color: transparent;
        
                        &:after {
        
                            content: '';
                            position: absolute;
                            left: 25px;
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: #333;
                            width: 20px;
                            height: 20px;
                            mask-image: url(/assets/img/ico/search.svg);
                            mask-size: contain;
                            mask-position: center center;
                            mask-repeat: no-repeat;
            
                        }
        
                        &--show-cancel {
        
                            &:after {
        
                                mask-image: url(/assets/img/ico/error.svg);
        
                            }
        
                        }
        
                    }
        
                    input.total {
        
                        border: 0;
                        background-color: transparent;
                        position: absolute;
                        right: 0;
                        width: 35px;
                        top: 0;
                        text-align: right;
                        opacity: .5;
                        padding: 0;
        
                    }
        
                    input.input {
        
                        padding: 0;
                        padding-right: 35px;
                        padding-left: 55px;
                        border: 0;
                        display: block;
                        width: calc(100% - 20px);
                        background-color: transparent;
                        position: relative;
                        z-index: 10;
        
                    }
        
                    @include atXXSmall {
        
                        width: 100%;
            
                    }
            
                }

                table {

                    border-collapse: collapse;
                    width: calc(100% - 10px);
                    background-color: white;
                    border-radius: 10px;
                    margin: 5px;
                    overflow: hidden;

                    tfoot,
                    tbody {

                        // tr:last-child {

                        //     td {
    
                        //         padding-bottom: 10px;
    
                        //     }
    
                        // }

                        tr {

                            td {
    
                                padding: 10px;
                                padding-right: 0;
                                border-bottom: 1px solid #eee;
                                // padding-bottom: 0;
    
                                input {
    
                                    margin: 0;
                                    border-radius: 10px;
                                    padding: 10px;
                                    width: 100%;
    
                                    &:read-only {
    
                                        background-color: white;
                                        padding-left: 0;
                                        padding-right: 0;
                                        font-weight: bold;
    
                                    }
    
                                }

                                .image {

                                    width: 60px;
                        
                                    div {
                        
                                        width: 40px;
                                        height: 40px;
                                        background-color: #eee;;
                                        border-radius: 10px;
                                        overflow: hidden;
                                        position: relative;
                    
                                        &:after {
                    
                                            content: '';
                                            background-color: #ccc;
                                            mask-repeat: no-repeat;
                                            mask-position: center;
                                            mask-image: url(/assets/img/ico/code-image2.svg);
                                            width: 20px;
                                            height: 20px;
                                            display: block;
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%,-50%);
                    
                                        }
                        
                                        img {
                        
                                            object-fit: cover;
                                            width: 100%;
                                            height: 100%;
                                            background-color: white;
                                            position: relative;
                                            z-index: 10;
                        
                                        }
                            
                                    }

                                }
    
                            }
    
                            td:first-child {
    
                                width: 40px;
    
                            }

                            td.cost {

                                width: 120px;

                            }
    
                            td:last-child {
    
                                padding-right: 10px;
    
                            }
    
                        }    

                    }

                    tfoot {

                        font-weight: bold;
                        // font-size: 14px;

                        td:first-child {
    
                            text-align: right;
                            padding-right: 10px;

                        }

                        input {

                            font-size: 24px;

                        }

                    }

                    thead {

                        tr {

                            td {

                                background-color: #f5f5f5;
                                color: #999;
                                padding-top: 5px;
                                padding-bottom: 5px;
                                padding-left: 10px;
                                padding-right: 10px;
                                font-size: 14px;

                            }

                        }

                    }

                }

                .btn {

                    background-color: $mainColor;
                    border-radius: 10px;
                    border: 0;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    margin: 0;

                    &:before {

                        width: 24px;
                        height: 24px;
                        content: '';
                        display: block;
                        background-color: white;
                        mask-repeat: no-repeat;
                        mask-position: center;
            
                    }

                    &--add {

                        &:before {

                            mask-image: url(/assets/img/ico/plus.svg);

                        }

                    }

                    &--remove {

                        background-color: #333;

                        &:before {

                            mask-image: url(/assets/img/ico/plus.svg);
                            transform: rotate(45deg);

                        }

                    }

                }

            }

            &--calc {

                width: 100%;
                margin: 20px auto;

                display: flex;
                align-items: right;
                justify-content: right;

                .table-container {

                    width: 100%;

                }

                table {

                    min-width: 0;

                    thead {

                        td {

                            text-align: center;

                        }

                    }

                    tbody {

                        tr td:first-child {

                            width: auto;

                        }

                        tr td:last-child {
                    
                            width: 96px;
                    
                        }

                    }
                
                }

            }

            &--total {

                // text-align: right;
                text-align: right;
                font-weight: bold;
                padding: 20px 0;
                margin: 0;
                background-color: transparent;
                font-size: 24px;

                @include atXSmall {
        
                    text-align: center;
        
                }

                span:last-child {

                    font-size: 32px;
                    padding: 0 0 0 15px;

                }
    
            }

            &--total-mini {

                padding: 0;
                padding-top: 20px;
                // font-weight: normal;
                font-size: 18px;

                span:last-child {

                    font-size: 18px;
                    padding: 0 0 0 15px;

                }

            }

            &--buttons {

                display: block;
                margin: 0;
                text-align: right;

                table {

                    text-align: left;

                }

                @include atSmall {

                    text-align: center;
                    
                }

                button {

                    display: inline-block;
                    margin: 10px;
                    margin-right: 0;
                    // margin-left: 0;

                    @include atSmall {

                        margin-right: 10px;
                        
                    }

                }

            }

            select,
            input {

                &.price {

                    width: 160px;

                }

                &.unit {

                    width: 160px;

                }

                &.price,
                &.unit {

                    flex: 0 1 auto;

                    @include atXSmall {

                        width: calc(100% - 20px);

                    }

                }

            }

            textarea {

                min-height: 90px;

            }

        }

    }

}