html,
body,
button,
input,
select {

    // font-family: 'Source Sans Pro', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    color: #333;

}

h1, h2, h3, h4, h5, h6,
// .section--hero .btn,
.logo {

    font-family: 'EB Garamond', serif;
    
}