.section {

    &--how {

        overflow: hidden;

        .ico {

            border-radius: 1000px;
            width: 180px;
            height: 180px;
            background-color: rgba(255,255,255,0.3);
            margin: 0 auto 20px;
            display: block;
            position: relative;

            span {

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 60%;
                height: 60%;
                mask-size: contain;
                mask-position: center center;
                mask-repeat: no-repeat;
                background-color: white;
                // background-color: #333;

            }
            
            &--list span {

                mask-image: url(/assets/img/ico/list.svg);

            }

            &--folk span {

                mask-image: url(/assets/img/ico/folk.svg);

            }

            &--cross span {

                mask-image: url(/assets/img/ico/cross.svg);

            }

            &--calc span {

                mask-image: url(/assets/img/ico/calc3.svg);

            }

            &--menu span {

                mask-image: url(/assets/img/ico/menu.svg);
                margin-top: -10px;

            }

        }

        background-color: $mainColor;
        // background-image: linear-gradient(-90deg, darken($mainColor, 0%), darken($mainColor, 10%));
        background-size: cover;
        background-position: center center;
        color: white;
        min-height: 480px;

        h2 {

            font-size: 64px;

        }

        canvas {

            position: absolute;
            // position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // width: 100%;
            // height: 100%;

        }

        p.main {

            font-size: 24px;
            margin-bottom: 40px;
            text-align: center;

        }

        .section > ul {

            margin: 0 -20px;

            display: flex;
            font-size: 24px;

            @include atSmall {

                display: block;
                text-align: center;

            }

            h3 {

                font-size: 32px;
                // font-weight: bold;
                padding-bottom: 20px;
                margin-bottom: 20px;
                text-align: center;

                border-bottom: 1px solid rgba(255,255,255,0.5);

            }

            & > li {

                padding: 20px;

                @include atSmall {

                    padding-top: 40px;
                    padding-bottom: 40px;
    
                }

            }

        }

    }

}