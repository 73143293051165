.section {

    &--hero {

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15vh 20px;
        // font-weight: bold;
        text-align: center;
        position: relative;

        font-size: 24px;
        font-size: clamp(24px, 2vw, 2vw);

        .main {

            max-width: 720px;
            color: white;
            position: relative;
            z-index: 10;
            margin: 0 auto;

            h2 {

                // font-size: 72px;
                font-size: clamp(48px, 4vw, 4vw);
                // font-weight: bold;
                line-height: 110%;
                margin: 0 0 30px;

            }

            p {

                font-size: clamp(18px, 2vw, 2vw);

            }

            .btn {

                // font-weight: bold;
                // text-transform: uppercase;
                display: inline-block;
                margin: 30px auto 0;
                border-radius: 10px;
                background-color: $mainColor;
                color: white;
                font-size: 24px;
                font-size: clamp(24px, 1.6vw, 1.6vw);
                padding: 1.7vw 2.8vw;
                border: 0;

                padding-top: clamp(24px, 1.7vw, 1.7vw);
                padding-right: clamp(40px, 2.8vw, 2.8vw);
                padding-left: clamp(40px, 2.8vw, 2.8vw);
                padding-bottom: clamp(24px, 1.7vw, 1.7vw);

                // font-weight: normal;
                cursor: pointer;
                transition: .5s;

                &:hover {

                    background-color: darken($mainColor, 10%);

                }

            }

        }

        .bg {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #333;
            // background-color: $mainColor;
            // background-image: linear-gradient(-90deg, darken($mainColor, 0%), darken($mainColor, 10%));
            background-size: cover;
            background-position: center center;

            img {

                object-fit: cover;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;

                object-fit: cover;
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

            }

            &:after {

                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.7));
                z-index: 2;

            }

        }

    }

}