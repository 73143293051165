* {

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

}

select,
button,
input {

	appearance: button;
	-moz-appearance: button;
	-webkit-appearance: button;

}

html,
body {

	-webkit-overflow-scrolling: touch;
	// background-color: #eee;
	background-color: white;

}

body {

	-webkit-text-size-adjust: 100%;
	min-width: 300px;
	overflow: scroll;
	overflow-x: hidden;

}

a {

    color: $mainColor;
    
}

*:focus {

	outline: 0;

}