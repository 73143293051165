.section {

    &--loading {

        display: flex;
        align-items: center;
        justify-content: center;

        .spinner {

            display: block;
            width: 98px;
            height: 98px;
            position: relative;
    
            &:before,
            &:after {
    
                content: '';
                border-radius: 50%;
                width: 98px;
                height: 98px;
                border: 2px solid rgba(127, 127, 127, 0);
                border-top-color: #333;
                animation: spinCenter 0.7s infinite linear;
                z-index: 5;
                transition: .5s;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
    
            }

            &:before {

                width: 64px;
                height: 64px;
                animation: spinCenterInvert 0.7s infinite linear;

            }

        }

    }

}