@import './modules/vars.scss';

:root {

    --main-color: $mainColor;

}

@import './base/global.scss';
@import './base/mixins.scss';
@import './base/reset.scss';
@import './base/scrollbar.scss';
@import './base/fonts.scss';

@import './modules/miniAlert.scss';

@import './modules/form.scss';

@import './modules/cookies.scss';

@import './modules/animations.scss';
@import './modules/dotsLoading';
@import './modules/loading-splash.scss';

@import './modules/alertReact.scss';
@import './modules/btn.scss';

@import './modules/header.scss';
@import './modules/content.scss';
@import './modules/footer.scss';

@import './modules/advanced-table.scss';

@import './modules/popup-fs.scss';
@import './modules/popup-admin.scss';

@import './modules/table.scss';
@import './modules/no-data.scss';

@import './modules/dynamic.scss';
@import './modules/table-scroll.scss';

@import './modules/section.scss';
@import './modules/section--title.scss';
@import './modules/section--faq.scss';
@import './modules/section--hero.scss';
@import './modules/section--pricing.scss';
@import './modules/section--how.scss';
@import './modules/section--page.scss';
@import './modules/section--loading.scss';
@import './modules/section--try.scss';

@import './modules/noscript.scss';

.allergens {

    &--description {

        margin: 20px 0;
        display: block;

        @include atXSmall {

            text-align: center;
            
        }
        
    }

    img {

        width: 20px;
        height: 20px;
        vertical-align: sub;
        display: inline-block;

    }

    span {

        display: inline-block;
        margin: 0 5px;

    }

}








.switch {

    position: relative;
    display: block;
    width: 76px;
    height: 42px;
    margin: 0 auto;

    input { 
    
        opacity: 0;
        width: 0;
        height: 0;

    }

    .slider {

        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;

        &:before {

            position: absolute;
            content: "";
            height: 34px;
            width: 34px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: .4s;

        }

      }
      
      input:checked + .slider {
        background-color: #333;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #333;
      }
      
      input:checked + .slider:before {
        content: '+';
        transform: translateX(34px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 10px;
      }
      
      .slider.round:before {
        border-radius: 7px;
        text-align: center;
        content: '%';
        // font-size: 24px;
        font-weight: bold;
        line-height: 34px;
        color: #666;
      }

  }
  
  
