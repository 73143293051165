.dots-loading {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    &:before,
    &:after {
    
        content: "";
    
    }

    div,
    &:before,
    &:after {

        margin: 35px 5px;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $mainColor;
        opacity: 0;

    }

    div {

        animation: opacitychange 1s ease-in-out 0.33s infinite;
        animation-fill-mode: infinite;
    
    }
        
    &:before {

        animation: opacitychange 1s ease-in-out infinite;

    }
        
    &:after {
    
        animation: opacitychange 1s ease-in-out 0.66s infinite;
        animation-fill-mode: infinite;
    
    }
    
}

@keyframes opacitychange {

    0%,
    100% { opacity: 0; }
    60% { opacity: 1; }

}