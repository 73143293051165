.advanced-table {

    width: 100%;

    &__header {

        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 10px;
        text-align: center;

        .btn {

            margin-left: auto;
            order: 2;
            border: 0;
            color: white;
            background-color: $mainColor;
            border-radius: 10px;
            margin-left: 10px;
            display: inline-block;
            padding: 15px 20px;
            text-decoration: none;

            &:before {

                display: inline-block;
                margin-right: 10px;
                background-color: white;
                width: 20px;
                height: 20px;
                content: '';
                vertical-align: sub;
                mask-image: url(/assets/img/ico/plus.svg);
                mask-size: contain;
                mask-position: center center;
                mask-repeat: no-repeat;

            }

            @include atXXSmall {

                margin: 0 auto 10px;
                // width: 100%;
    
            }

        }

        .search {

            background-color: #eee;
            flex: 1;
            border-radius: 10px;
            position: relative;
            overflow: hidden;

            .reset {

                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 45px;
                border: 0;
                height: auto;
                z-index: 20;
                cursor: pointer;

                &:after {

                    content: '';
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #333;
                    width: 20px;
                    height: 20px;
                    mask-image: url(/assets/img/ico/search.svg);
                    mask-size: contain;
                    mask-position: center center;
                    mask-repeat: no-repeat;
    
                }

                &--show-cancel {

                    &:after {

                        mask-image: url(/assets/img/ico/error.svg);

                    }

                }

            }

            input.total {

                position: absolute;
                border: 0;
                background-color: transparent;
                position: absolute;
                right: 15px;
                width: 30px;
                top: 50%;
                transform: translateY(-50%);
                text-align: right;
                opacity: .5;

            }

            input.input {

                padding: 15px;
                padding-right: 45px;
                padding-left: 45px;
                border: 0;
                display: block;
                width: 100%;
                background-color: transparent;
                position: relative;
                z-index: 10;

            }

            @include atXXSmall {

                width: 100%;
    
            }
    
        }

        @include atXXSmall {

            display: block;

        }

    }

    ul.pagination {

        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        li {

            position: relative;
            display: block;
            text-align: center;
            width: 36px;
            line-height: 36px;
            margin: 2px;
            font-size: 14px;
            // font-weight: bold;
            // padding: .5rem .75rem;
            // line-height: 1.25;
            // border: 1px solid #ddd;
            // border-right: 1px solid #ddd;
            // border-right: 0;
            // border-top: 0;
            cursor: pointer;
            border-radius: 10px;
            transition: .5s;

            &.active {

                // background-color: #f5f5f5;
                border-color: #333;
                background-color: #333;
                color: white;

            }

            &:first-child {

                // border-radius: 0 0 0 10px;

            }

            &:last-child {

                // border-right: 0;
                // border-right: 1px solid #ddd;
                // border-radius: 0 0 10px 0;

            }

        }

    }

    table {

        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        min-width: 640px;
        // font-size: 16px;
    
        th {
    
            font-weight: bold;
            // font-size: 16px;
            cursor: default;
    
            i {
    
                &.sort {
    
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    display: inline-block;
                    height: 18px;
                    width: 10px;
                    margin-left: 5px;
                    vertical-align: bottom;
                    position: relative;
    
                    &--desc {

                        &:after {
                            
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 7px solid $mainColor;
                            content: '';
                            position: absolute;
                            margin-top: 4px;

                        }
    
                    }
    
                    &--asc {
    
                        &:after {
                            
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 7px solid $mainColor;
                            content: '';
                            position: absolute;
                            margin-top: 4px;

                        }
    
                    }
    
                }
    
            }
    
        }
        
        th,
        td {
    
            padding: 20px;
            border-bottom: 1px solid #eee;
            border-left: 0;
            border-right: 0;

            &.right {

                text-align: right;

            }

            &.small {

                font-size: 12px;

            }
    
            &.center {
    
                text-align: center;
    
            }
    
            &.button-icon {
    
                // width: 40px;
                // padding-left: 0;
                // padding-right: 0;

                &.calculator {

                    width: 60px;
                    padding-right: 0;

                }

                &.add {

                    width: 60px;
                    padding-right: 0;

                }

                &.btn {

                    width: 120px;

                }
                
                .btn {

                    border: 1px solid #ff6600;
                    color: #ff6600;
                    border-radius: 10px;
                    padding: 12px 20px;
                    background: transparent;
                    width: auto;
                    text-decoration: none;

                }

                .calculator,
                .calendar,
                .add,
                .edit,
                .view {
    
                    // margin: 0 auto;
                    width: 40px;
                    height: 40px;
                    display: block;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    background-color: $mainColor;
                    border: 0;
                    cursor: pointer;
                    transition: background-color .3s;

                    &:hover {

                        background-color: #333;

                    }
    
                }

                .calculator {

                    mask-image: url(/assets/img/ico/calculator.svg);
    
                }

                .calendar {

                    mask-image: url(/assets/img/ico/calendar.svg);
    
                }

                .add {

                    mask-image: url(/assets/img/ico/plus.svg);
    
                }
    
                .edit {

                    mask-image: url(/assets/img/ico/pencil.svg);
    
                }
    
                .view {
    
                    mask-image: url(/assets/img/ico/eye.svg);
    
                }
    
            }
    
            &.image {
    
                width: 60px;
    
                div {
    
                    width: 40px;
                    height: 40px;
                    background-color: #eee;;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;

                    &:after {

                        content: '';
                        background-color: #ccc;
                        mask-repeat: no-repeat;
                        mask-position: center;
                        mask-image: url(/assets/img/ico/code-image2.svg);
                        width: 20px;
                        height: 20px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);

                    }
    
                    img {
    
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        background-color: white;
                        position: relative;
                        z-index: 10;
    
                    }
    
                }
    
            }
    
        }
    
        tr th:first-child,
        tr td:first-child {
    
            padding-left: 20px;
    
        }
    
        tr th:last-child,
        tr td:last-child {
    
            padding-right: 20px;
            text-align: right;
    
        }

        // tr td:last-child {
    
        //     padding-right: 0;
        //     text-align: right;
    
        // }
    
    }    

}